import * as React from 'react'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
//import Header from '../components/Header'
import { PageContent, ChartTitle } from '../styles/SharedStyles'
import Notes from '../components/Notes'
import PageHeader from '../components/PageHeader'
import IG31Chart from '../components/IG31/IG31Chart'
import IG31Table from '../components/IG31/IG31Table'
import Footer from '../components/Footer'
import ImageExport from '../components/ImageExport'
import {
  LOCATION_LABELS,
  INCOME_GROUPS,
  UNI_REGIONS,
  WHO_LOCATION_OPTIONS,
  INC_LOCATION_OPTIONS,
  UNI_LOCATION_OPTIONS,
  REGION_LOOKUP
} from '../utils/const'
import {
  useQueryParamString,
  getQueryParams
} from 'react-use-query-param-string'
import SelectRegion from '../components/SelectRegion'
import RegionInfo from '../components/RegionInfo'
import Disclaimer from '../components/Disclaimer'

const Ig31Page = ({ data, pageContext }) => {
  const { t } = useTranslation()
  const { sectionId } = pageContext
  const { content, footerContent, regions } = data
  const params = getQueryParams()
  const [reg, setReg] = useQueryParamString('reg', params.reg ?? 'GBL')

  const isInc = INCOME_GROUPS.includes(reg)
  const isUni = UNI_REGIONS.includes(reg)
  const isGbl = reg === 'GBL' || !reg
  const regionLabel = isGbl ? t('global') : t(LOCATION_LABELS[reg])
  return (
    <>
      <PageHeader
        sectionId={sectionId}
        title={content.title}
        eyebrowText={content.eyebrowText}
      />
      {/* <Header content={content} /> */}
      <PageContent>
        <SelectRegion
          reg={reg}
          setReg={setReg}
          whoOptions={WHO_LOCATION_OPTIONS}
          incOptions={INC_LOCATION_OPTIONS}
          uniOptions={UNI_LOCATION_OPTIONS}
        />

        <ChartTitle>{`${content.chartTitle}—${regionLabel}`}</ChartTitle>
        <ImageExport
          title={`${content.chartTitle}—${regionLabel}`}
          dataSource={content.dataSourceNotesRt}
        >
          <IG31Chart
            // regions={
            //   isGbl
            //     ? data.global
            //     : isInc
            //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
            //     : isUni
            //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
            //     : data.regions.nodes.find((d) => d.iSO3 === reg)
            // }
            regions={regions.nodes.filter((t) => t.CODE === REGION_LOOKUP[reg])}
            title={`${content.chartTitle}—${regionLabel}`}
          />
        </ImageExport>
        <IG31Chart
          // regions={
          //   isGbl
          //     ? data.global
          //     : isInc
          //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
          //     : isUni
          //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
          //     : data.regions.nodes.find((d) => d.iSO3 === reg)
          // }
          regions={regions.nodes.filter((t) => t.CODE === REGION_LOOKUP[reg])}
          title={`${content.chartTitle}—${regionLabel}`}
        />
        <IG31Table
          // regions={
          //   isGbl
          //     ? data.global
          //     : isInc
          //     ? data.income.nodes.find((d) => d.incomeGroup === reg)
          //     : isUni
          //     ? data.unicef.nodes.find((d) => d.uNICEFRegion === reg)
          //     : data.regions.nodes.find((d) => d.iSO3 === reg)
          // }
          regions={regions.nodes.filter((t) => t.CODE === REGION_LOOKUP[reg])}
        />
        {!isGbl && (isUni || isInc) && <Disclaimer />}
        {!isGbl && <RegionInfo reg={reg} />}
      </PageContent>
      <Notes content={content} />
      <Footer content={footerContent} />
    </>
  )
}

export default Ig31Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    content: googleIg31CmsSheet(language: { eq: $language }) {
      id
      language
      eyebrowText
      title
      intro1Heading
      intro1Text
      intro2Heading
      intro2Text
      chartTitle
      definitionNotesRt
      measurementNotesRt
      calculationNotesRt
      dataSourceNotesRt
    }
    footerContent: googleFooterCmsSheet(language: { eq: $language }) {
      citationRt
      descriptionRt
    }
    regions: allRegions(filter: { IA2030_IND: { eq: "IG3.1" } }) {
      nodes {
        IA2030_IND
        CATEGORY
        CODE
        INDCODE
        DIMENSION2
        VALUE_NUM
        YEAR
      }
    }
  }
`
// global: googleIg31Sheet(regionType: { eq: "GBL" }) {
//   id
//   iSO3
//   region
//   memberState
//   dTPcv3Coverage2019
//   dTPcv3Coverage2020
//   dTPcv3Coverage2021
//   dTPcv3Coverage2022
//   dTPcv3Coverage2023
//   dTPcv3Coverage2024
//   dTPcv3Coverage2025
//   dTPcv3Coverage2026
//   dTPcv3Coverage2027
//   dTPcv3Coverage2028
//   dTPcv3Coverage2029
//   dTPcv3Coverage2030
//   pCV32019
//   pCV32020
//   pCV32021
//   pCV32022
//   pCV32023
//   pCV32024
//   pCV32025
//   pCV32026
//   pCV32027
//   pCV32028
//   pCV32029
//   pCV32030
//   mCV22019
//   mCV22020
//   mCV22021
//   mCV22022
//   mCV22023
//   mCV22024
//   mCV22025
//   mCV22026
//   mCV22027
//   mCV22028
//   mCV22029
//   mCV22030
//   hPVc2019
//   hPVc2020
//   hPVc2021
//   hPVc2022
//   hPVc2023
//   hPVc2024
//   hPVc2025
//   hPVc2026
//   hPVc2027
//   hPVc2028
//   hPVc2029
//   hPVc2030
// }
// regions: allGoogleIg31Sheet(filter: { regionType: { eq: "REG" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     dTPcv3Coverage2019
//     dTPcv3Coverage2020
//     dTPcv3Coverage2021
//     dTPcv3Coverage2022
//     dTPcv3Coverage2023
//     dTPcv3Coverage2024
//     dTPcv3Coverage2025
//     dTPcv3Coverage2026
//     dTPcv3Coverage2027
//     dTPcv3Coverage2028
//     dTPcv3Coverage2029
//     dTPcv3Coverage2030
//     pCV32019
//     pCV32020
//     pCV32021
//     pCV32022
//     pCV32023
//     pCV32024
//     pCV32025
//     pCV32026
//     pCV32027
//     pCV32028
//     pCV32029
//     pCV32030
//     mCV22019
//     mCV22020
//     mCV22021
//     mCV22022
//     mCV22023
//     mCV22024
//     mCV22025
//     mCV22026
//     mCV22027
//     mCV22028
//     mCV22029
//     mCV22030
//     hPVc2019
//     hPVc2020
//     hPVc2021
//     hPVc2022
//     hPVc2023
//     hPVc2024
//     hPVc2025
//     hPVc2026
//     hPVc2027
//     hPVc2028
//     hPVc2029
//     hPVc2030
//   }
// }
// income: allGoogleIg31Sheet(filter: { regionType: { eq: "INC" } }) {
//   nodes {
//     id
//     iSO3
//     region
//     memberState
//     incomeGroup
//     dTPcv3Coverage2019
//     dTPcv3Coverage2020
//     dTPcv3Coverage2021
//     dTPcv3Coverage2022
//     dTPcv3Coverage2023
//     dTPcv3Coverage2024
//     dTPcv3Coverage2025
//     dTPcv3Coverage2026
//     dTPcv3Coverage2027
//     dTPcv3Coverage2028
//     dTPcv3Coverage2029
//     dTPcv3Coverage2030
//     pCV32019
//     pCV32020
//     pCV32021
//     pCV32022
//     pCV32023
//     pCV32024
//     pCV32025
//     pCV32026
//     pCV32027
//     pCV32028
//     pCV32029
//     pCV32030
//     mCV22019
//     mCV22020
//     mCV22021
//     mCV22022
//     mCV22023
//     mCV22024
//     mCV22025
//     mCV22026
//     mCV22027
//     mCV22028
//     mCV22029
//     mCV22030
//     hPVc2019
//     hPVc2020
//     hPVc2021
//     hPVc2022
//     hPVc2023
//     hPVc2024
//     hPVc2025
//     hPVc2026
//     hPVc2027
//     hPVc2028
//     hPVc2029
//     hPVc2030
//   }
// }
// unicef: allGoogleIg31Sheet(filter: { regionType: { eq: "UNI" } }) {
//   nodes {
//     id
//     iSO3
//     memberState
//     uNICEFRegion
//     dTPcv3Coverage2019
//     dTPcv3Coverage2020
//     dTPcv3Coverage2021
//     dTPcv3Coverage2022
//     dTPcv3Coverage2023
//     dTPcv3Coverage2024
//     dTPcv3Coverage2025
//     dTPcv3Coverage2026
//     dTPcv3Coverage2027
//     dTPcv3Coverage2028
//     dTPcv3Coverage2029
//     dTPcv3Coverage2030
//     pCV32019
//     pCV32020
//     pCV32021
//     pCV32022
//     pCV32023
//     pCV32024
//     pCV32025
//     pCV32026
//     pCV32027
//     pCV32028
//     pCV32029
//     pCV32030
//     mCV22019
//     mCV22020
//     mCV22021
//     mCV22022
//     mCV22023
//     mCV22024
//     mCV22025
//     mCV22026
//     mCV22027
//     mCV22028
//     mCV22029
//     mCV22030
//     hPVc2019
//     hPVc2020
//     hPVc2021
//     hPVc2022
//     hPVc2023
//     hPVc2024
//     hPVc2025
//     hPVc2026
//     hPVc2027
//     hPVc2028
//     hPVc2029
//     hPVc2030
//   }
// }
