import React from 'react'
//import { useTranslation } from 'gatsby-plugin-react-i18next'
import { ANTIGEN_LOOKUP_API } from '../../utils/const'
import Table from '../Table'
import { useTranslation } from 'react-i18next'

const IG31Table = ({ regions }) => {
  const { t } = useTranslation()
  //const allYearsWithData = []
  // const wrangleData = () => {
  //   const allCategories = []

  //   const allData = Object.entries(regions)
  //     .map(([key, value]) => {
  //       //gets the years that have data so we know what columns to make
  //       const keysToExclude = [
  //         'memberState',
  //         'iSO3',
  //         'id',
  //         'region',
  //         'incomeGroup',
  //         'uNICEFRegion'
  //       ]
  //       if (!keysToExclude.includes(key)) {
  //         const year = +key.slice(-4)
  //         const vaccine = key.slice(0, -4)
  //         allCategories.push(vaccine)
  //         if (value) allYearsWithData.push(year)
  //         return { year, vaccine, value }
  //       } else return null
  //     })
  //     .filter((item) => item)

  //   const categories = Array.from(new Set(allCategories))
  //   console.log('allData', allData)
  //   const formattedData = categories.map((category) => {
  //     const catData = allData
  //       .filter((d) => d.vaccine === category)
  //       .reduce((acc, d) => {
  //         acc[d.year] =
  //           d.value >= 0
  //             ? (d.value * 100).toFixed(0) + '%'
  //             : d.value === -1
  //             ? t('not measured')
  //             : t('no data')
  //         return acc
  //       }, {})
  //     return Object.assign(
  //       {
  //         vaccine: t(ABBR_TO_VACCINE[category])
  //       },
  //       catData
  //     )
  //   })
  //   return formattedData
  // }
  const BASELINE_YEAR = 2019
  const wrangleData = () => {
    const categories = Array.from(new Set(regions.map((r) => r.DIMENSION2)))
    const formattedData = categories.map((category) => {
      const catData = regions
        .filter((d) => d.DIMENSION2 === category)
        .reduce((acc, d) => {
          acc[d.YEAR === BASELINE_YEAR ? 'Baseline' : d.YEAR] =
            d.VALUE_NUM.toFixed() + '%'
          return acc
        }, {})
      return Object.assign(
        {
          vaccine: t(ANTIGEN_LOOKUP_API[category])
        },
        catData
      )
    })
    const order = ['DTP3', 'PCV3', 'MCV2', 'HPVc']
    const orderedData = formattedData.sort((a, b) =>
      order.indexOf(a.vaccine) > order.indexOf(b.vaccine) ? 1 : -1
    )
    return orderedData
  }
  const ig31Data = wrangleData()

  const data = React.useMemo(() => ig31Data, [ig31Data])

  const years = Array.from(
    new Set(regions.map((r) => (r.YEAR === 2019 ? null : r.YEAR)))
  )
    .filter((item) => item)
    .sort((a, b) => (a > b ? 1 : -1))
  const yearHeaders = years.map((year) => {
    return {
      Header: `${year}`,
      accessor: `${year}`
    }
  })

  const columns = React.useMemo(() => {
    const baseHeader = [
      {
        Header: t('vaccine'),
        accessor: 'vaccine' // accessor is the "key" in the data
      },
      {
        Header: `${t('Baseline')} (${BASELINE_YEAR})`,
        accessor: 'Baseline' // accessor is the "key" in the data
      }
    ]
    return baseHeader.concat(yearHeaders)
  }, [yearHeaders, t])

  return (
    <Table
      data={data}
      columns={columns}
      noBoldTopRow={true}
      description={t(
        'A table that lists vaccines and the percentage of coverage each year.'
      )}
    />
  )
}

export default IG31Table
